import { onDomReady } from "../utils/on-dom-ready";

/**
 * Sets the state of the hidden checkbox that indicates whether the checkbox group is valid or not
 * @param checkboxGroup
 */
const validateCheckboxGroup = (checkboxGroup: HTMLElement) => {
  const checkedCheckboxes = checkboxGroup.querySelectorAll(
    ".multi-checkbox__checkbox:checked"
  );
  const hiddenCheckbox = checkboxGroup.querySelector<HTMLInputElement>(
    ".multi-checkbox__hidden"
  );

  if (!hiddenCheckbox) {
    return;
  }

  hiddenCheckbox.checked = checkedCheckboxes.length > 0;
};

onDomReady(() => {
  const checkboxGroups = document.querySelectorAll<HTMLElement>(
    ".multi-checkbox--required"
  );

  checkboxGroups.forEach((checkboxGroup) => {
    const checkboxes = checkboxGroup.querySelectorAll<HTMLInputElement>(
      ".multi-checkbox__checkbox"
    );

    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener("change", () => {
        validateCheckboxGroup(checkboxGroup);
      });
    });

    validateCheckboxGroup(checkboxGroup);
  });
});
